import { useState } from "react";
import { MdOutlineUnfoldMore, MdCheck } from "react-icons/md";
import { Listbox } from "@headlessui/react";
import { matchSorter } from "match-sorter";

export interface Option<T> {
    label: string;
    value: T;
}

interface Props<T> {
    name: string;
    errorMsg?: string;
    required?: boolean;
    error: boolean | undefined | Error;
    clearError?: () => void;
    options: Option<T>[];
    onAddChange?: (args: Option<T>) => void;
    onChange: (args: Option<T>) => void;
    value: Option<T>;
    showLabel?: boolean;
    placeholder?: string;
}

export default function Dropdown<T>(props: Props<T>) {
    const {
        name,
        options,
        errorMsg,
        onAddChange = () => void 0,
        required = false,
        value,
        onChange,
        error = null,
        showLabel = true,
        placeholder = "Select",
    } = props;
    const [query, setQuery] = useState<string>("");

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join("");
    }

    let filteredOptions: Option<T>[] = [];
    if (query) {
        if (query.length === 0) {
            filteredOptions = options;
        } else {
            filteredOptions = matchSorter(options, query, { keys: ["label"] });
        }
    } else {
        filteredOptions = options;
    }
    return (
        <>
            <Listbox
                as="div"
                value={value}
                onChange={(arg: Option<T>) => {
                    onChange(arg);
                    onAddChange(arg);
                }}
                className="mb-3 w-full"
            >
                <div className="relative mt-2 text-left">
                    <Listbox.Button className="flex w-full grow bg-nafl-charcoal-600 text-body-base  border-[2px] rounded-lg p-3 bg-nafl-charcoal-600 border-nafl-sponge-500 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-nafl-sys-complete placeholder:text-nafl-charcoal-400 placeholder:text-body-base text-left">
            <span className="grow truncate">
              {value ? (
                  <span className="text-nafl-charcoal-100">{value?.label}</span>
              ) : (
                  <span className="text-nafl-charcoal-400">{placeholder}</span>
              )}
            </span>
                        <MdOutlineUnfoldMore className="text-nafl-sponge-500 text-body-2xl" />
                    </Listbox.Button>

                    {filteredOptions.length > 0 && (
                        <Listbox.Options className="relative md:absolute z-10 mt-3 max-h-60 ml-[0.2rem] w-[calc(100%-0.4rem)] overflow-auto  py-1  bg-nafl-charcoal-700 rounded-md shadow-lg focus:outline focus:outline-2 focus:outline-nafl-sys-complete">
                            {filteredOptions.map((op: Option<T>) => {
                                return (
                                    <Listbox.Option
                                        key={op.label}
                                        value={op}
                                        className={({ active }) =>
                                            classNames(
                                                "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-nafl-grey-700 border-b border-nafl-grey-600 last:border-0"
                                            )
                                        }
                                    >
                                        {({ active, selected }) => (
                                            <>
                        <span
                            className={`block truncate ${
                                selected || op.label === value?.label
                                    ? `text-nafl-sponge-500`
                                    : ""
                            }`}
                        >
                          {op.label}
                        </span>

                                                {selected && (
                                                    <span
                                                        className={classNames(
                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                    >
                            <MdCheck className="text-nafl-sponge-500 text-body-xl" />
                          </span>
                                                )}
                                            </>
                                        )}
                                    </Listbox.Option>
                                );
                            })}
                        </Listbox.Options>
                    )}
                </div>
            </Listbox>
            {error ? (
                <p
                    className="mt-2 text-left text-sm text-dangerous-600"
                    id="email-error"
                >
                    {errorMsg}
                </p>
            ) : (
                <p className="mt-2 text-sm text-dangerous-600" id="email-error">
                    {""}
                </p>
            )}
        </>
    );
}