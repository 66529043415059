{
  "allowlistId": 1,
  "starttime": "1687262400",
  "endtime": "1687370400",
  "allocations": {
    "0x5ce8559c8f4bc50d9aae216c510eec91335a97be": 5,
    "0x7b9667164534bc86f923585ef6a05a8fdb05f1cc": 5,
    "0x6df31405b841750e9a680bc85ac567193104fec1": 5,
    "0x18db3d5ffa4b947c3ddac98a50217d53b95460d6": 5,
    "0x8ce499b590bbc33664eb07c666f130ff329b065e": 10,
    "0x63668cf2c3a168257d8d21f911353323597721e9": 10,
    "0x3ebf9f437c8d509804cd08f18d1137b5f0ae8acc": 20,
    "0x7e1af5573f43473176d3ee86546e243937247b57": 5,
    "0x7e3d993a8d63c25d1db5f7ff8a8d6c59091003b1": 25,
    "0xb2767629602cf390168d4ffa52d283e894b01222": 5,
    "0x082621627a916e82a437662afbbb92a5493c0710": 5,
    "0x8a961048613c57ed5ad9d6ffc49f6cd8bf375272": 5,
    "0xc63e522d262e3e90d78c7053346c97eedd2f1125": 10,
    "0x34cf7ac942a815ddeddf1319ce91dea69af46dcb": 40,
    "0x45982e9bb82d80382174e5b7828661a16138d6dd": 5,
    "0x4e45aee4ea18686f6037f536d200597b9b7cd5f6": 5,
    "0x85e41d90d865101bf78567446e2e24ecd9389349": 5,
    "0xf011f97d87ba4ac949c940a84b12e276e13f0529": 25,
    "0x7fc9743d7aef4ad2705b405f5ae46f595cd44860": 22,
    "0x07b8aa0f0e279cb7413598578b3f4924a982d276": 10,
    "0x2e670c1059941fcedfc79355fa735337bf890e22": 5,
    "0xd39872438eea76855ac5cf8c120dc334b43e61a3": 3,
    "0x7d233c0f9fc98bbabdf20fdd3619b51cf3816886": 5,
    "0x6f26c18c28bb37731caf00af0d1f7c3e69cc5f79": 5,
    "0x31270810b7a0a76cc4664c76d9cbc48d85bd6505": 5,
    "0x0c16d08cdd114f9830d3cbb1045df4e8da6bae8c": 5,
    "0x5e98ecd9409afe9e5d61039513af02f9436f44d3": 5,
    "0xc063b28ff2617682960faf1036f5e2247dfd4da1": 5,
    "0x6ccab95e44916265140b27cc339f6d28a3f03f69": 3,
    "0x40046103bd11102f7d5408d276e58e9bb88da4af": 12,
    "0xf3d40460d3582d477544e095d9f90e791abedc5f": 5,
    "0x3574060c34a9da3be20f4342af6db4f21bc9c95e": 10,
    "0x3b36cb2c6826349eec1f717417f47c06cb70b7ea": 5,
    "0x0b24dc8537340dcf4ff89f522f32ceb6395ef396": 5,
    "0xc8e7671eef71784d3c89c66b61119afbf9c9c7bd": 5,
    "0x50e99dae79906140e0dc00d1ec52fc075f6463d6": 5,
    "0xda89c70f2c946abdf2e195266a3a8579c694c421": 5,
    "0x6be26976f31b020fe5708dad62d6fd263743c79e": 5,
    "0xc8968da29067c7cda02ff732637f08245675b5cb": 5,
    "0x37c3f5a9c4f3297b026f044f93b9bdb68b57e169": 5,
    "0xe6dfd97988e78802b1960133bcf193f1c5b78d1b": 5,
    "0xcf237e16b82cb772ccf9a414c032ed93b6d24ce0": 3,
    "0xb2a83ad63d15de30c6edaebdd110c175476393be": 3,
    "0x5d5d5384426ecaa902df19985b754f5a6b2fc8ac": 3,
    "0xcc4b920bb70f59130c1dc6078c1ee594e3e66178": 10,
    "0x1b0392959b52dee7b6c741a19e41e54fcf65b10b": 5,
    "0x9c4fd5c9cf18240541e71a45c0bd52bebd2e402a": 10,
    "0x9cab9f3ce861d713fb74d7b827c6b00d00a7e783": 5,
    "0x87a951ed1d96cc7dde4cc0ca3c21643831137c00": 5,
    "0x16d82705f9bf5b9cc09fc1445d0269afd91b073e": 5,
    "0xa02f1e40b6343388d38fbd49f2ffdbc80bf9a343": 5,
    "0x3b1C79E322f5f2C4Ce6966cDe4339cb2b6F0fC3f": 7,
    "0xf587ba70fe86bf3c48fcb3f2be8724659a26adc8": 5,
    "0xffec67f3bbc314a01376e4cb814f18ebb140a9f0": 5,
    "0x3ae2ae1a0d37a8de9419a53a895fc1295c421210": 5,
    "0x98e3e05f1e652829911d87568cd77497502aa625": 10,
    "0x5c8cd7ddebfeddd2c46d187f4e145aea4d9be124": 5,
    "0x779e1fe96a04877d15d27c30178fbc4e42a72f5f": 9,
    "0xf049ee7639f2a0069dd52305ca46d8478081b114": 5,
    "0x0b5bfb355f553a267460bb4cb1c768a8d4940687": 12,
    "0x60869907d98aeaa98f66640b72eeb1bcdbab5936": 10,
    "0xc6f9ea6aba34cd2470c3d8ec3065dce02665b79d": 5,
    "0x311b1eb37651057ad9c0acd18068ab8291995db9": 5,
    "0x870ecdd99318836cd9cf23eb370d5991692bb07c": 5,
    "0x66f6794168b6e0c8a55a675ea262625304e755f7": 7,
    "0x576b521405d3943bb37411568180328a3e465abd": 5,
    "0xe4cc2c1a1a29886ede73317f1674a772dc211852": 5,
    "0xba105ed077a41d6d4aa6f559790bb7c2d95d0c4e": 5,
    "0xd5301a94d5337f3d5efc0f7a59b0608593e88d06": 10,
    "0xa8d99f626e592f44bfde3e491297023f63082891": 30,
    "0x3a2d0a41ac14637e663c90aabb7243f5f614f5e3": 7,
    "0x332160e2d0e557903cb655c7e54d00221ec333de": 3,
    "0xa7ba8eacfa8e68b7f4089d73948eaf0418fd3c2e": 5,
    "0x8c0ee75d12043908c4a2e470ac630d92073f82d4": 5,
    "0x1125dd1603b890b6d4e416b533eb5b222637278e": 5,
    "0x8105de1b2f6872640e31c14f5037f6c280e86ab7": 5,
    "0xd690ec90b50532fc819391d0d667cfc6f98ddc2b": 5,
    "0xadb3f2a1883131de8da9c9d301612a335b4bf4d4": 5,
    "0x8da1e5237b780b04e4f8072e24337d059cb08446": 10,
    "0xd3fde0e70445b2e96661e7a14c7d1cf2791ed8a7": 5,
    "0x43a86525d2b359977f59c37ea83d4f7017670c2c": 5,
    "0x1821821d8c03990901bdfe1756c5665ec6667095": 5,
    "0x0dc27ce78e6d80c14e8f894c8b5aecdf27d781f7": 5,
    "0x0baafedd7fe51db130eb30a9eda66095c630ab6c": 5,
    "0x587e030343153e59ae28172a24e814fe320c720d": 5,
    "0xc753439b1a97d54a36443a17d7f54fff06fdd42d": 5,
    "0xc3d18fb1ece6a34d813ea15260692361f85e1aeb": 3,
    "0x5ca16bd7ee42ead14fdef6ce0e318b8ae4e0729f": 5,
    "0xa605930e119971c5e404de4e820997b27827950c": 5,
    "0x6067024f59addcacac3caca3e4d6a26443df2462": 5,
    "0xc469b48b1555f34426ea980d6cee472c4121961c": 3,
    "0xb0799d85fafc89c81c6c84f1c049769166d60627": 5,
    "0x3459f7e8ad91e997fe1cbc03bf165067bfbdb9ab": 5,
    "0x8e4540dfed42ad6e94fad58cdd2dc1d538fe848d": 15,
    "0x90e642e3f6ba7ad5fe7581f38587225ef177e21c": 15,
    "0x97d44e486af79fec3393a470a83152e1f480eb82": 15,
    "0x8906c6b2ba5d8663168bf5a8cf685164ad1bfe77": 10,
    "0x99ad72e124883f52739a2fd5c2562ec1f1db52e3": 10,
    "0x18e35ef935f94794cb7a4fb403a12d99a0a6de3f": 12,
    "0x95bb6fe72e4468404c89ef8a0830cd210f442d3a": 15,
    "0xb6166931b7d4cb447d839277a9192bc19633b71d": 10,
    "0x75a541db9ae93054165f3cc4480e2280e426e6f3": 3,
    "0xA832Ed7924d650eB9F476F53cD7056b0B87EB718": 50,
    "0xb728b50ea253d38026270f1aafe4ec19714f5c2e": 5,
    "0x85bb9364e8cf4d62c7bcdd80307ecce46714bb61": 3,
    "0xf1f5f59c026f470135b35f6b6a4550c4f93403c7": 5,
    "0x5252aa0a56dffe64a74cb99c7525ceebfa352d80": 5,
    "0xf6d01277e989138f8575a574ba12e70d0dea4ef7": 5,
    "0xffe3e5147929b8b9b129489463381dce6626eb05": 12,
    "0xbe27d585a0eb7f4aa9f9930376beb777bb0e1385": 5,
    "0x73490deef94d34ee9e16863e6bbc8e5437b33f93": 10,
    "0x330abdf76d042321f876d97853ce02a9a3336d11": 9,
    "0x8cfbfeb2873f5142c979c68ed3731b95e6617a28": 5,
    "0x0c1bf1aea0e8f5ddc771078df6f02fd33980fd80": 5,
    "0xc09ddd87b175156855e943ced0e3d661db258e78": 5,
    "0x08f035ab1edee2bcb483c430a0a0edbcc7d4b91b": 5,
    "0x709206df74db120db83042810043ad2cac304982": 3,
    "0x7348874721fb51f5fe78843b3af60328474dfc61": 5,
    "0x88fe79e3e74bb28faaf1532aaff4d2cddb594f74": 5,
    "0x3169a6ff4746853b9cac6d39d96db706cbd06941": 5,
    "0xd3b7ee3a78e0d6b29a4ec8d05892ad44c253b2a4": 10,
    "0x13678d14be70a4b5a906e7cd507f6502d52ecf20": 5,
    "0x1e8a2bcb241d3ad860c95fe200c2cc6aed1fa5c8": 5,
    "0xbe8bb1e908b700209d6b6c7cd36f26dc07028a8d": 5,
    "0x8c0ed0e12819f906b2f63fc28d865c695d1e6a1d": 5,
    "0xb4ed04164fb9a2b530dc3cde21c726dd7db94636": 12,
    "0x26e7f5f193354b22da271a57433acadab2c79480": 5,
    "0x4bac941342ff30d03ae7589ce45fc0e9e3b12831": 5,
    "0x86a41524cb61edd8b115a72ad9735f8068996688": 12,
    "0x7f27ac16c5a95ffa06dd2e76bef9d907df4bb5a2": 3,
    "0xb26c44133e17be0bdbda9353d52c327e0254da84": 5,
    "0x0ea473507529c0327d982aa568b9f638ccc43f9b": 5,
    "0x97c5602d17b4f1a91a53eec85f7e786b074befce": 5,
    "0x0683ddac721a96e31e8bdc61ac2a6b2835747bb5": 5,
    "0xbd737f7901ac40afeb211ff312650341d6df65f9": 5,
    "0x0e7c5e7607e58adc519e966eb50f08e296a7fbd7": 5,
    "0xd01914ab58d829d335c8fcc948b88408caaff740": 5,
    "0x71dc583b5208807d6c290946bc43b367135579d6": 5,
    "0x172fa5499ed084efaafc49ed02a922d185d3cf6b": 5,
    "0x68db169981bd22a82c278083f0a655a6249cb048": 3,
    "0x91ae5443d036370e1c8fc6edeb8e04f15c388665": 5,
    "0xa7e640e019fb8925695e62e6dc181082147b3be9": 7,
    "0xeb5281e2da3b9cb7c7c5d906af0e850445a86200": 5,
    "0xb2d67d34f198157c41ed1dfd9853ee59639e9bef": 5,
    "0x032f587c96c5e9c75c739c08a73e62ffe869bbde": 3,
    "0xf550c56b8e60bc1042358630c68b13356a42a0d5": 5,
    "0x03f139783ece1e975c162e9a765873718d8f4709": 5,
    "0x1769e88b8c5a0b3496a790e185e1053465f2f7f5": 5,
    "0x8f49f7807d73a059ff1d08dfb568913293edfa83": 3,
    "0xebdfd7f9daa044f164c52384dd38364e363bfe60": 15,
    "0xbe3b173c6ee409e37e2d479c935bb0babefe7e97": 10,
    "0x7b1232938c13a7445de31844e8aaf8c3a81e7d1d": 3,
    "0xcbc26ec2e214498ce08b7765e10a074b0b69444c": 5,
    "0x0BCad5f4414adDf2B030B414d42ED3b9f3aB22a1": 5,
    "0x280776f592afcd2f78a0d9fc67bbfe6b7d2717b9": 5,
    "0xa68ee1d6aceb7de55eaa119133dc33a6d73de993": 10,
    "0x6dd72540eb027246f8e55d7a86e8d587dcd4b803": 5,
    "0x6e48a2b12004ee6cd4a6f887705044bf1577618d": 10,
    "0xc06e493851c3a119cf4f65e5c185b8fb811c978c": 5,
    "0xe2d70f3254fc0f2f72e933191737d15c4907d6a2": 5,
    "0xf8d27d950d178bbf58c39b42eca0b5783138c5a4": 5,
    "0x765aacb083d7bd1a72d90cdb93f1aeba576d6ad8": 5,
    "0x176746e6f3be14f7c213dc78049568c698ce73ea": 5,
    "0xcc15619fd8c006c3456167545bd19edf4c6f3397": 10,
    "0xe29216b803d5a56554b788da673c8298e6ebfc8a": 5,
    "0x14c12e8e804bf6e44094037a762cf22ace687dd3": 5,
    "0xa15d66da2cbe5e70de2fcbba23cd8335eff66557": 5,
    "0x89e18a63d58fad8d9850d346f27a3a04d011c9a0": 10,
    "0x6bf517a7d49251faf0825f5332d9c0a5c43ae3f0": 5,
    "0xbf30c58434fbe153f28551a2cf9814a082f20b7d": 5,
    "0xc01d390b07206aeaea7f0d262156415bbde1005a": 5,
    "0x56d8822091ac3286a4de12b7c1b84390a79920f3": 9,
    "0x984b18b1823fef04a4ca7cf1e8a0ef5359fa522f": 5,
    "0xfc39dd253b7f9d4f8b5cb9d6a2c911080f504327": 3,
    "0x6637c7bfdc61359fc8e9c48c68fecd590cd4d4ff": 3,
    "0xce1e62ddd03000f87898ab4eb41b14d818fb90d3": 5,
    "0xBc99e8C0c29c80269871E737644845E08c73b015": 5,
    "0xcb53f5935ab74e8ae27b71fe6117f63aa3651935": 20,
    "0x82e45d3642409439c978263e1358c9b3781c2452": 3,
    "0x796c4f19d5534415d2a827cfbeeeaf2a1f25e6aa": 5,
    "0xbdaefe578294842fb433769c6b0d95130099e96e": 5,
    "0x42757298759773e1af7199b18f6ad2307dfdcd88": 3,
    "0x2a31ce4806ba88843691c2aacc4de8999ab90444": 5,
    "0x9354ba1fc2fccb08929642b566043a5222f22199": 5,
    "0x4905325cc33fa68c8217998f65c7909ae893ed61": 5,
    "0x9fa8a5bd92b207165d461d3eeff51e7d7107244e": 5,
    "0xfb013a4b0263c44cd7b18c6b6595ba49900142a4": 5,
    "0x138f5ccf242f9276d3e002712014d41b48e6068e": 5,
    "0x95d52fbdf8c8775fe8ab3b45fdcac2ff38e04aca": 5,
    "0x5e3b73978e1d4691f499bed5447846437876b6aa": 34,
    "0x536038c5fa79045feab179e868cf0c953d97f2f0": 4,
    "0x549c262d21164b57376704813875d5bc558e5dab": 2,
    "0x7dc2cbdd4cd596ddb7fb8879bb6ce0d701514e62": 2,
    "0xc9feb6cc1e5ae6675bcb0fe12374ffcf53f053b7": 2,
    "0xfc589a494572e763f3fdda92d3e5c131d8aa63be": 2,
    "0xd33bb8c58ac6cad9d146604e224714abb02009e4": 2,
    "0x11a3b367936cdba1df64c131ef43a2c78c9ea70f": 2,
    "0xbcc951c95371c503d655af06f5902ac1e8f76308": 4,
    "0x76fbd4ad2dc56494cd4df33ec73a68dff268725f": 8,
    "0xcee098ad89ea73583262a5143d67d9e2f94121bc": 2,
    "0xfe636ce5cd6ec9b47b784cb5edfc4b0cffa77f6e": 4,
    "0xe52e0afff61c695eee3b05207f7a33c8910cb466": 2,
    "0x421470c15bd386b3d75648682c19bb968c1b3b56": 2,
    "0xfaf241943ccaefefaf6e36ca7c4475ba814d1655": 2,
    "0x6d5016a8962f7259da5fbca85c199ac8073f9960": 6,
    "0xf3846ec842c2490d6289858e28038cfbec660689": 2,
    "0x11c486e7bf25b8548d8f181420316f01fd43f4a2": 4,
    "0x441b63ca9f57f197c54e5b0f6c704758a78043c7": 2,
    "0x4ac67cee233cb14801093f0f520560ccce60c8b5": 2,
    "0x1eb1fe371cfd35d56e4660ecd93b825669b1d4b3": 4,
    "0x233adaae8a5a04893d6bf6f64a0c74caff53abc0": 2,
    "0x0bf9bf7b062a04f14faa27b75392bdad638fecae": 4,
    "0x1564c1df64828ee88ecfb3f9dd10ea595188ee8b": 2,
    "0x01d8a1bca7b594a71688fc9bcd17e0d91971db39": 4,
    "0x098b4761cb164b8440f022df0601b30e060649c1": 2,
    "0x72994f4daf824b144ff174fb12ffbf4aee649ae9": 2,
    "0xd430ed0ddc7f406fa2dd475788ea5e7eb16aa6e8": 6,
    "0xda63dacfccd4b08f3be46995dec9f05d46b922ba": 2,
    "0x6078564a9c657d56d93bdd415bb1922fc6ae4aaf": 2,
    "0xe781c864946b7a32da99a8bed658689366540419": 2,
    "0xa6d8f7cbca4dfb277a24691a4db3530c9482342c": 2,
    "0xbe6a5992d259990ddf43dbd681568db292ba47d7": 2,
    "0x1cdd4b5625d56c2b3de1a3656f3e71dcd1db552e": 2,
    "0xfd96f75963fbaff02341e3cff43f48c1f3b13343": 2,
    "0xcbcfc1b6846fe4952cd7ec496b6d76fe5789f889": 2,
    "0x4609b7b1b9b97eb6539b24122d7a9a9939cf678d": 2,
    "0x7540000cab63979795c7d4b326cadbb00ed24a04": 2,
    "0x2b2a90d412debabdf94c076f3daed5ad52b8c42b": 2,
    "0xef7f4e538e6a05cb998e50ac0b25243ac18f71ae": 2,
    "0xe111fda134ff7e5da80d77d6e2f9cd7ef2a0be57": 2,
    "0xe93d6e1c629e5d06397a417ac7f655dc7112e418": 2,
    "0x241cAB326be5314C264417ef1B229cA84E7D0c81": 5
  }
}
