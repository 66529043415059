import React, { useEffect } from 'react'
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'
import * as Sentry from "@sentry/react";
import MintButton from "./MintButton";
import abi from '../contracts/ElevationPass.json';
import {getProof, getUserAllowance } from "../utils/whitelists";
import { BigNumber } from "ethers";
import { inactivePhase, publicPhase, omniHolderAllowListPhase, partnerAllowlistPhase, generalAllowlistPhase} from "./MintPage";
import Dropdown, {Option} from "./Dropdown";

interface DropdownMintPhaseOption {
  name: string;
  key: number;
}

const MintCard = ({ mintPhase }) => {
  const maxSupply = Number(process.env.ELEVATION_MAX_SUPPLY)
  const maxElevationMintsPerWallet = Number(process.env.MAX_ELEVATION_PUBLIC_MINTS_PER_WALLET)

  const [selectedMintPhase, setSelectedMintPhase] = React.useState(mintPhase)
  const [allowance, setAllowance] = React.useState(null);
  const [totalNumberMinted, setTotalNumberMinted] = React.useState(null);
  const [numberMinted, setNumberMinted] = React.useState(0);
  const [numberToMint, setNumberToMint] = React.useState(0);
  const [mintedDuringPublicSale, setMintedDuringPublicSale] = React.useState(0);
  const [soldOut, setSoldOut] = React.useState(false);
  const [proof, setProof] = React.useState();
  const [prepareError, setPrepareError] = React.useState(null)
  const [publicTransactionIsSuccess, setPublicTransactionIsSuccess] = React.useState(false)
  const [publicTransactionIsError, setPublicTransactionIsError] = React.useState(false)
  const [allowlistTransactionIsError, setAllowlistTransactionIsError] = React.useState(false)
  const [allowlistTransactionIsSuccess, setAllowlistTransactionIsSuccess] = React.useState(false)
  const [allDataLoaded, setAllDataLoaded] = React.useState(false)
  const { chain } = useNetwork()
  const { address } = useAccount()

  const [mintedDuringPublicSaleLoaded, setMintedDuringPublicSaleLoaded] = React.useState(false)

  const elevationAbi = abi.abi

  const totalNumbermintedByAddress = () => {
    return numberMinted;
  }

  const getMintPhaseTitle = (mintPhase) => {
    switch (mintPhase) {
      case inactivePhase:
        return "Inactive"
      case omniHolderAllowListPhase:
        return "Omnipotent Holder Allowlist"
      case partnerAllowlistPhase:
        return "Partner / VIP Allowlist"
      case generalAllowlistPhase:
        return "General Allowlist"
      case publicPhase:
        return "Public Sale"
      default:
        return "Inactive"
    }
  }

  const [selectedMintPhaseDropdownValue, setSelectedMintPhaseDropdownValue] = React.useState(getDropdownOptions().at(-1))

  useEffect(() => {
    if (selectedMintPhaseDropdownValue) {
      setSelectedMintPhase(selectedMintPhaseDropdownValue.value.key)
    }
  }, [selectedMintPhaseDropdownValue])


  useEffect(() => {
    getMaxAllowance();
  });

  useEffect(() => {
    if (address) {
      Sentry.setUser({ address: address });
    }
  }, [address])

  useEffect(() => {
    if (chain) {
      Sentry.setContext("Chain", {
        "name": chain.name,
        "id": chain.id,
      })
    }
  }, [chain])

  useEffect(() => {
    if (
      totalNumberMinted &&
      maxSupply &&
      maxElevationMintsPerWallet
    ) {
      setAllDataLoaded(true)
    }
  }, [allowance, totalNumberMinted, numberMinted, maxSupply, maxElevationMintsPerWallet])

  useEffect(() => {
    if (selectedMintPhase == inactivePhase) {
      setNumberToMint(0)
    } else if (selectedMintPhase == omniHolderAllowListPhase || selectedMintPhase == partnerAllowlistPhase || selectedMintPhase == generalAllowlistPhase) {
      setNumberToMint(allowance - numberMinted)
    } else {
      setNumberToMint(maxElevationMintsPerWallet - mintedDuringPublicSale)
    }
  }, [allowance, numberMinted, mintedDuringPublicSale])


  useEffect(() => {
    setSoldOut(totalNumberMinted >= maxSupply)
  }, [totalNumberMinted, maxSupply])

  function canIncrease() {
    if (selectedMintPhase == omniHolderAllowListPhase || selectedMintPhase == partnerAllowlistPhase || selectedMintPhase == generalAllowlistPhase) {
      return !(numberToMint >= (allowance - numberMinted));
    }
    return !(numberToMint >= 3 || (numberToMint >= (maxElevationMintsPerWallet - mintedDuringPublicSale)));
  }

  useEffect(() => {
    refetch();
  }, [selectedMintPhase])

  function increaseNumberToMint() {
    if (!canIncrease()) return
    setNumberToMint(numberToMint + 1)
  }

  function decreaseNumberToMint() {
    if (numberToMint == 0) return
    setNumberToMint(numberToMint - 1)
  }

  function getDropdownOptions(): Option<DropdownMintPhaseOption>[] {
    const options: Option<DropdownMintPhaseOption>[] = [
      {
        label: getMintPhaseTitle(inactivePhase),
        value: {
          name: getMintPhaseTitle(inactivePhase),
          key: inactivePhase
        }
      },
      {
        label: getMintPhaseTitle(omniHolderAllowListPhase),
        value: {
          name: getMintPhaseTitle(omniHolderAllowListPhase),
          key: omniHolderAllowListPhase
        }
      },
      {
        label: getMintPhaseTitle(partnerAllowlistPhase),
        value: {
          name: getMintPhaseTitle(partnerAllowlistPhase),
          key: partnerAllowlistPhase
        }
      },
      { label: getMintPhaseTitle(generalAllowlistPhase),
        value: {
          name: getMintPhaseTitle(generalAllowlistPhase),
          key: generalAllowlistPhase
        }
      },
      {
        label: getMintPhaseTitle(publicPhase),
        value: {

          name: getMintPhaseTitle(publicPhase),
          key: publicPhase
        }
      }
    ]
    switch (mintPhase) {
      case inactivePhase:
        return options.slice(0, 1)
      case omniHolderAllowListPhase:
        return options.slice(1, 2)
      case partnerAllowlistPhase:
        return options.slice(1, 3)
      case generalAllowlistPhase:
        return options.slice(1, 4)
    }
    return options.slice(-1)


  }

  function getMaxAllowance() {
    if (address == null) {
      return
    }
    if (selectedMintPhase == inactivePhase) {
      setAllowance(0)
      return
    }
    if (selectedMintPhase == publicPhase) {
      setAllowance(maxElevationMintsPerWallet)
      return
    }
    const allowance = getUserAllowance(selectedMintPhase, address)
    setAllowance(allowance)
  }

  function getAllowlistIdFromMintPhase(mintPhase) {
    switch (mintPhase) {
        case omniHolderAllowListPhase:
            return 1
        case partnerAllowlistPhase:
            return 2
        case generalAllowlistPhase:
            return 3
        default:
            return 0
    }
  }

  const {refetch} = useContractRead({
    address: process.env.CONTRACT_ADDRESS,
    abi: elevationAbi,
    functionName: 'mintedDuringAllowlistSale',
    args: [getAllowlistIdFromMintPhase(selectedMintPhase), address],
    onSuccess: (data) => {
      setNumberMinted(data);
    },
    onError(error) {
      Sentry.captureException(error)
    },
    enabled: (selectedMintPhase != inactivePhase && selectedMintPhase != publicPhase),
    watch: false,
  })

  useContractRead({
    address: process.env.CONTRACT_ADDRESS,
    abi: elevationAbi,
    functionName: 'totalSupply',
    onSuccess: (data) => {
      setTotalNumberMinted((data as BigNumber).toNumber());
    },
    onError(error) {
      Sentry.captureException(error)
    },
    watch: false
  })

  useContractRead({
    address: process.env.CONTRACT_ADDRESS,
    abi: elevationAbi,
    functionName: 'mintedDuringPublicSale',
    args: [address],
    onSuccess: (data) => {
      setMintedDuringPublicSale((data));
      setMintedDuringPublicSaleLoaded(true)
    },
    onError(error) {
      Sentry.captureException(error)
    },
    watch: false
  })

  const {
    config: allowlistConfig,
    error: prepareAllowlistMintError
  } = usePrepareContractWrite({
    address: process.env.CONTRACT_ADDRESS,
    abi: elevationAbi,
    functionName: 'allowlistMint',
    args: [numberToMint, proof],
    enabled: Boolean(proof),
    onSettled(data, error) {
      mintAllowlist()
    },
    onError(error) {
      Sentry.captureException(error)
    }
  })

  const { data: allowlistData, isLoading: isAllowlistLoading, isError: allowlistContractWriteIsError, write: allowlistWrite } = useContractWrite({
    ...allowlistConfig,
    onError(error) {
      Sentry.captureException(error)
    }
  })

  const { isLoading: allowlistIsLoading, isError: allowlistTransactionIsHTTPError } = useWaitForTransaction({
    hash: allowlistData?.hash,
    onError(error: Error) {
      Sentry.captureException(error)
    },
    onSuccess(data) {
      if (data.status == 1) {
        // success
        setAllowlistTransactionIsSuccess(true)
      } else {
        // error
        setAllowlistTransactionIsError(true)
        Sentry.captureMessage("Transaction failed with logs " + data.logs)
      }
    }
  })

  const {
    config: publicConfig,
    error: preparePublicMintError
  } = usePrepareContractWrite({
    address: process.env.CONTRACT_ADDRESS,
    abi: elevationAbi,
    functionName: 'publicMint',
    args: [numberToMint],
    onError(error) {
      Sentry.captureException(error)
    },
    enabled: (selectedMintPhase == publicPhase && numberToMint > 0 && mintedDuringPublicSaleLoaded),
  })

  useEffect(() => {
    if (allowance - totalNumbermintedByAddress() < 1) {
      // Transaction will fail because user is no longer allowed.
      return
    }
    if ((selectedMintPhase == generalAllowlistPhase || selectedMintPhase == omniHolderAllowListPhase || selectedMintPhase == partnerAllowlistPhase)) {
      setPrepareError(prepareAllowlistMintError)
    } else if (selectedMintPhase == publicPhase) {
      setPrepareError(preparePublicMintError)
    }
  }, [prepareAllowlistMintError, preparePublicMintError, selectedMintPhase])

  const { data: publicData, isLoading: isPublicLoading, isError: publicContractWriteIsError, write: publicWrite } = useContractWrite({
    ...publicConfig,
    onError(error: Error) {
      Sentry.captureException(error)
    },
  })

  const { isLoading: publicIsLoading, isError: publicTransactionIsHTTPError } = useWaitForTransaction({
    hash: publicData?.hash,
    onError(error: Error) {
      Sentry.captureException(error)
    },
    onSuccess(data) {
      if (data.status == 1) {
        // success
        setPublicTransactionIsSuccess(true)
      } else if (data.status == 0) {
        // error
        setPublicTransactionIsError(true)
        Sentry.captureMessage("Transaction failed with logs " + data.logs)
      }
    }
  })

  useEffect(() => {
    if (allowlistTransactionIsSuccess || publicTransactionIsSuccess) {
      setTotalNumberMinted(totalNumberMinted + numberToMint);
    }
  }, [allowlistTransactionIsSuccess, publicTransactionIsSuccess])

  useEffect(() => {
    if (mintPhase) {
      setSelectedMintPhaseDropdownValue(
          getDropdownOptions().at(-1)
      )
    }
  }, [mintPhase])

  const mintAllowlist = () => {
    if (!isAllowlistLoading) {
      allowlistWrite?.()
    }
  }

  function listMint() {
    if (numberToMint > 0) {
      if (selectedMintPhase == 1) {
        if (getUserAllowance(1, address) > 0) {
          setProof(getProof(1, address))
        }
      } else if (selectedMintPhase == 2) {
        if (getUserAllowance(2, address) > 0) {
          setProof(getProof(2, address))
        }
      } else if (selectedMintPhase == 3) {
        if (getUserAllowance(3, address) > 0) {
          setProof(getProof(3, address))
        }
      }
      mintAllowlist()
    }
  }

  function publicMint() {
    if (numberToMint > 0 && !isPublicLoading) {
      publicWrite?.()
    }
  }

  function getPrepareErrorMessage() {
    if (prepareError != null) {
      let string_message = "Something prevents you from minting."
      try {
        string_message = prepareError["error"]["message"]
      } catch {
        return string_message
      }
      return string_message
    }
  }

  function isHttpError(): boolean {
    return publicTransactionIsHTTPError || allowlistTransactionIsHTTPError || publicContractWriteIsError || allowlistContractWriteIsError
  }

  function isTransactionError(): boolean {
    return publicTransactionIsError || allowlistTransactionIsError
  }

  function getBorderColor(): string {
    if (isTransactionError()) {
      return "border-red-500"
    } else if (allowlistTransactionIsSuccess || publicTransactionIsSuccess) {
      return "border-green-500"
    }

    return "border-yellow"
  }

  function getHash() {
    if (allowlistTransactionIsSuccess) {
      return allowlistData?.hash
    } else if (publicTransactionIsSuccess) {
      return publicData?.hash
    }
  }

  function getMax(): number {
    if (selectedMintPhase == omniHolderAllowListPhase || selectedMintPhase == partnerAllowlistPhase || selectedMintPhase == generalAllowlistPhase) {
      const max = allowance - totalNumbermintedByAddress()
      if (max >= 0) {
        return max
      } else {
        // can be less then 0 when allowance is 0 in different phase.
        return 0
      }
    } else if (selectedMintPhase == publicPhase) {
      return maxElevationMintsPerWallet - mintedDuringPublicSale
    } else {
      return 0
    }
  }

  return (
    <div>
      {allDataLoaded ?
        <div
          className={"bg-grey mt-24 rounded-2xl w-full text-neutral-200 flex flex-col border-4 text-center p-6 text-white relative " + getBorderColor()}>
          <h2 className="lg:text-5xl text-3xl text-white inline pt-6 m-0 pb-4">{(publicTransactionIsSuccess || allowlistTransactionIsSuccess) ?
            <span>CONGRATULATIONS</span> : <span>ELEVATION PASS MINT</span>}</h2>
          <div
            className="inset-0 gap-x-24 gap-y-8 border-solid border-2 border-white rounded-2xl flex flex-col md:flex-row p-8 mb-8">
            <div className="flex flex-col flex-auto ">
              <div className="top-0 text-2xl inline m-0">Remaining</div>
              <div
                className="inset-x-0 bottom-0 text-4xl">{maxSupply - totalNumberMinted}/{maxSupply}</div>
            </div>
            <div className="flex flex-col flex-auto">
              <div className="top-0 text-2xl inline m-0 ">Price</div>
              <div className="inset-x-0 bottom-0 inline m-0  text-4xl">FREE</div>
            </div>
          </div>
          {(!publicTransactionIsSuccess && !allowlistTransactionIsSuccess && !soldOut) &&
            <div>
              <div>
                <Dropdown<DropdownMintPhaseOption>
                    name={"mintPhaseDropdown"}
                    error={false}
                    options={getDropdownOptions()}
                    onChange={setSelectedMintPhaseDropdownValue}
                    value={selectedMintPhaseDropdownValue as Option<DropdownMintPhaseOption>} />
              </div>
              <div
                className="inset-0 border-solid border-2 border-white p-1 flex flex-row bg-neutral-400 text-[#464646] text-lg">
                <div className="flex pl-2 p-1 flex-row flex-auto float-right text-4xl">
                  <div className={`p-1 cursor-pointer ${numberToMint != 0 ? "" : "text-stone-500"}`}
                    onClick={decreaseNumberToMint}>-
                  </div>
                  <div className="p-1">{numberToMint}</div>
                  <div className={`p-1 cursor-pointer ${canIncrease() ? "" : "text-stone-500"}`}
                    onClick={increaseNumberToMint}>+
                  </div>
                </div>
                <div className="pr-2 pt-2 h-max align-middle">
                  <div className="text-4xl pr-2 align-middle">{getMax()}<span
                    className="text-xl pl-2">MAX</span></div>
                </div>
              </div>
              <div className="pt-2 h-max float-right text-xl">You can mint up to <span
                className="text-yellow">{getMax()}</span> more
              </div>
              <div className="h-[2px] mt-12 mb-12 bg-white" />
            </div>
          }
          <div className="grow h-16 pb-2">
            <MintButton
              allowance={allowance - totalNumbermintedByAddress()}
              onMintClick={(selectedMintPhase == publicPhase) ? publicMint : listMint}
              disabled={(numberToMint < 1)}
              loading={(allowlistIsLoading || publicIsLoading)}
              success={(allowlistTransactionIsSuccess || publicTransactionIsSuccess)}
              soldOut={soldOut}
              hash={getHash()}
            />
            {isHttpError() && <div className="pt-2 pb-2 text-red-500">Something went wrong</div>}
            {(prepareError && !soldOut) && <div className="pt-2 pb-2 text-red-500">{getPrepareErrorMessage()}</div>}
          </div>
        </div>
        :
        <div
          className={"bg-grey mt-32 rounded-2xl w-full text-neutral-200 flex flex-col border-4 text-center p-6 text-white relative " + getBorderColor()}>
          <h2 className="text-5xl text-white inline pt-6 m-0 pb-4">Loading collection data..</h2>
        </div>
      }
    </div>
  )
}

export default MintCard
