import React from "react";
import Navbar from './Navbar'
import MintCard from "./MintCard";
import WhitelistIndicator from "./WhitelistIndicator";

export const inactivePhase = 0;
export const omniHolderAllowListPhase= 1;
export const partnerAllowlistPhase = 2;
export const generalAllowlistPhase= 3;
export const publicPhase = 4;

const MintPage = () => {
  const omniHolderAllowlist= {
    "startTime": Number(process.env.OMNI_HOLDERS_MINT_START_TIME),
    "endTime": Number(process.env.OMNI_HOLDERS_MINT_END_TIME),
  }
  const partnerAllowlist = {
    "startTime": Number(process.env.PARTNER_MINT_START_TIME),
    "endTime": Number(process.env.PARTNER_MINT_END_TIME),
  }
  const generalAllowlist= {
    "startTime": Number(process.env.ALLOWLIST_MINT_START_TIME),
    "endTime": Number(process.env.ALLOWLIST_MINT_END_TIME),
  }

  const publicSaleStartTime = Number(process.env.PUBLIC_MINT_START_TIME);
  const [currentPhase, setCurrentPhase] = React.useState(-1);

  function getCurrentPhase() {
    if (omniHolderAllowlist == null || partnerAllowlist == null || generalAllowlist == null || publicSaleStartTime == null) return null
    // to seconds
    const currentDate = Date.now() / 1000

    if (currentDate > publicSaleStartTime) {
      return publicPhase
    } else if (currentDate > generalAllowlist["startTime"]) {
      return generalAllowlistPhase
    } else if (currentDate > partnerAllowlist["startTime"]) {
      return partnerAllowlistPhase
    } else if (currentDate > omniHolderAllowlist["startTime"]) {
      return omniHolderAllowListPhase
    } else {
      return inactivePhase
    }
  }

  function setNextPhaseIfNeeded() {
    setCurrentPhase(getCurrentPhase())
  }

  function getCountDownTimeInMillis(): number {
    const currentPhase_ = getCurrentPhase()
    if (omniHolderAllowlist == null || partnerAllowlist == null || generalAllowlist == null || publicSaleStartTime == null) return Date.now()
    if (currentPhase_ == inactivePhase) {
      return omniHolderAllowlist["startTime"] * 1000
    } else if (currentPhase_ == omniHolderAllowListPhase) {
      return partnerAllowlist["startTime"] * 1000
    } else if (currentPhase_ == partnerAllowlistPhase) {
      return generalAllowlist["startTime"] * 1000
    } else if (currentPhase_ == generalAllowlistPhase) {
      return generalAllowlist["endTime"] * 1000
    } else {
      return Date.now()
    }
  }

  return (
    <div>
      {(currentPhase != null) &&
        <div className="m:h-screen relative z-10 bg-red flex flex-col h-screen justify-between font-bebas">
          <div className="pr-16 pt-8">
            <div className="float-right mr-56">
              <WhitelistIndicator />
            </div>
          </div>
          <main className="mb-auto">
            <div className="flex flex-col items-center w-full">
              <div className="flex-2 w-64">
              </div>
              <div className="flex-1 w-9/12 max-w-[500px] pt-32 pb-24">
                {(currentPhase != -1) &&
                    <MintCard mintPhase={currentPhase}/>
                }
              </div>
            </div>
          </main>
          <div className="h-10 pl-16 pr-16 pb-32">
            <Navbar phase={currentPhase} countDownTime={getCountDownTimeInMillis} countDownCallback={setNextPhaseIfNeeded} />
          </div>
        </div>
      }
    </div>
  );
}

export default MintPage;
