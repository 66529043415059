import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'
import {ethers} from "ethers";

import omniAllocation from "./omniAllocation.json"
import partnerAllocation from "./partnerAllocation.json"
import allowlistAllocation from "./allowlistAllocation.json"

export type AllowlistProof = {
  allowlistId: number;
  allowance: number;
  proof: any[];
}

export const whitelists = {
  1: omniAllocation["allocations"],
  2: partnerAllocation["allocations"],
  3: allowlistAllocation["allocations"],
}

const merkleTrees = {
  1: {
    tree: getMerkleTree(1),
  },
  2: {
    tree: getMerkleTree(2),
  },
  3: {
    tree: getMerkleTree(3),
  }
}

function getLowercaseWhitelistDictionary(phase: number): {} {
  const whitelist = whitelists[phase];
  if (!whitelist) {
    return {};
  }
  return Object.keys(whitelist).reduce((acc, key) => {
    acc[key.toLowerCase()] = whitelist[key];
    return acc;
  }, {});
}


export function getUserAllowance(phase: number, address: string): number {
  // if the address is not in the whitelist, return 0
  const allowance = getLowercaseWhitelistDictionary(phase)[address.toLowerCase()];
  if (!allowance) {
      return 0;
  }
  return allowance;
}

function getMerkleTree(phase: number): MerkleTree {
  const whitelistInfo = getLowercaseWhitelistDictionary(phase)

    // and create a list of leaves
  const leaves = Object.keys(whitelistInfo).map((address) => {
      const allowance = whitelistInfo[address];
      return keccak256(
          ethers.utils.solidityPack(
          ["address", "uint8"],
          [address, allowance]
          )
      );
  })
  return new MerkleTree(leaves, keccak256, { sort: true });
}

export function getProof(phase: number, address: string): AllowlistProof {
  const tree = merkleTrees[phase].tree;
  const whitelistInfo = getLowercaseWhitelistDictionary(phase)
  const allowance = whitelistInfo[address.toLowerCase()];
  const leaf = keccak256(
      ethers.utils.solidityPack(
          ["address", "uint8"],
          [address, allowance]
      )
  );
  return {
    allowlistId: phase,
    allowance: allowance,
    proof: tree.getHexProof(leaf)
  };
}

