import React from 'react'
import {getUserAllowance} from "../utils/whitelists";
import {useAccount, useConnect, useDisconnect} from "wagmi";
import {InjectedConnector} from "wagmi/dist/connectors/injected";

const WhitelistIndicator = () => {
  const {address} = useAccount()
  const { disconnect } = useDisconnect()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  function getUserAllowanceForPhase(phase: number) {
    const allowance = getUserAllowance(phase, address)
    if (allowance > 0) {
        return <span className="text-green-600">Allowed: {allowance}</span>
    } else {
        return <span className="text-red-600">Allowed: 0</span>
    }
  }

  function getWalletSubString() {
    if (address) {
      return address.substring(0, 4) + "..." + address.substring(address.length - 6, address.length)
    }
  }

  return (
    <div className="bg-[#FFFFFF] w-[250px] rounded-xl p-2 p-4 drop-shadow-lg absolute">
    {
      address ?
        <div>
          <div className="cursor-pointer" onClick={() => {disconnect()}}>Connected wallet: <span className="float-right" >{getWalletSubString()}</span></div><br/>
          Omnipotent phase: <span className="float-right">{getUserAllowanceForPhase(1)}</span><br/>
          Partner/vip phase: <span className="float-right">{getUserAllowanceForPhase(2)}</span><br/>
          Allowlist phase:<span className="float-right">{getUserAllowanceForPhase(3)}</span><br/>
          Public phase:<span className="float-right"><span className="text-green-600">Allowed: 3</span></span><br/>
        </div>
        :
        <div className="text-center">
          <button onClick={() => connect()}>
            Not connected
          </button>
        </div>
    }
    </div>
  )
}

export default WhitelistIndicator